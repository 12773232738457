// Import Swiper and modules
import { Swiper, Navigation, Pagination, Lazy, Autoplay } from 'swiper';

// Install modules
Swiper.use([Navigation, Pagination, Lazy, Autoplay]);

const swiperDelay = 3000;
const swiperDelayLonger = 15000;
const selectorHeadSlider = '.js-head-slider';
const selectorButterfactslider = '.js-butterfacts-slider';
const selectorHeadSwiper = `.swiper-container${selectorHeadSlider}`;
const selectorSwipePrev = '.js-swiper-button-prev';
const selectorSwipeNext = '.js-swiper-button-next';
const selectorSwiperPagnationHeader = '.js-swiper-pagination-header';

// only working if swiper 'loop: false' - otherwise virtual slides are added to the dom by swiper:
function hideSingleSlideElements(elementsToHide) {
  for (let i = 0; i < elementsToHide.length; i += 1) {
    document.querySelector(elementsToHide[i]).style.display = 'none';
  }
}

function initHeadSwiper() {
  const headSwiper = new Swiper(selectorHeadSwiper, {
    autoplay: {
      delay: swiperDelay,
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
    loop: true,
    disableOnInteraction: false,
    speed: 1200,
    pagination: {
      el: selectorSwiperPagnationHeader,
      type: 'bullets',
      clickable: true,
    },
  });
}

function initButterfactsSwiper() {
  const butterfactsSwiper = new Swiper(selectorButterfactslider, {
    autoHeight: true,
    autoplay: {
      delay: swiperDelayLonger,
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
    loop: true,
    disableOnInteraction: false,
    speed: 1200,
    navigation: {
      nextEl: selectorSwipeNext,
      prevEl: selectorSwipePrev,
    },
  });

  if (butterfactsSwiper.slides.length < 2) {
    // hide pagination and buttons if only 1 slide:
    const elementsToHide = [
      `${selectorButterfactslider} ${selectorButterfactslider}`,
      `${selectorButterfactslider} ${selectorSwipePrev}`,
      `${selectorButterfactslider} ${selectorSwipeNext}`,
    ];
    hideSingleSlideElements(elementsToHide);
  }
}

function init() {
  if (document.querySelector(selectorHeadSwiper) !== null) {
    initHeadSwiper();
  }
  if (document.querySelector(selectorButterfactslider) !== null) {
    initButterfactsSwiper();
  }
}

export default {
  init,
};
