import _ from 'lodash';

// https://github.com/zurb/foundation-zurb-template/blob/v6.4/src/assets/js/lib/foundation-explicit-pieces.js
import { Foundation } from 'foundation-sites/js/foundation.core';
import { Equalizer } from 'foundation-sites/js/foundation.equalizer';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader';
import { Triggers } from 'foundation-sites/js/foundation.util.triggers';

const $ = window.jQuery;
const selectorFlagEqualizer = '.js-is-equalizer';
const selectorShowMore = '.js-btn-more-recipes';
const showMoreItemsButton = document.querySelector(selectorShowMore);
let ticking = false;

Foundation.addToJquery($);
Triggers.init($, Foundation);

Foundation.plugin(Equalizer, 'Equalizer');
Foundation.plugin(MediaQuery, 'MediaQuery');
Foundation.plugin(onImagesLoaded, 'onImagesLoaded');
Foundation.plugin(Triggers, 'Triggers');

const initEventlistener = () => {
  if (document.querySelector(selectorFlagEqualizer) !== null) {
    // re-init equalizer on resize:
    window.addEventListener('resize', () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          Foundation.reInit('equalizer');
          ticking = false;
        });
        ticking = true;
      }
    });

    // equalize items added to the DOM:
    showMoreItemsButton.addEventListener('click', () => {
      _.delay(() => {
        Foundation.reInit('equalizer');
      }, 200);
    });
  }
};

function initFoundationScripts() {
  _.delay(() => {
    console.log('foundation - now!');
    $(document).foundation();
  }, 500);
  initEventlistener();
}

function init() {
  initFoundationScripts();
}

export default {
  init,
};
