// import $ from 'jquery';

const selectorhiddenItem = '.js-initially-hidden';
const selectorShowMore = '.js-btn-more-recipes';
const selectorLinkMore = '.js-link-more-recipes';
const btnShowMore = document.querySelector(selectorShowMore);
const linkShowMore = document.querySelector(selectorLinkMore);

const hiddenItems = document.querySelectorAll(selectorhiddenItem);

// @link http://veno.es/venobox/
function initshowMore() {
  console.log('initshowMore init');
  btnShowMore.addEventListener('click', (event) => {
    console.log('show more clicked');
    event.target.classList.add('hide');

    hiddenItems.forEach((item) => {
      item.classList.add('fade-in');
    });

    linkShowMore.classList.add('fade-in');
  });
}

const init = () => {
  if (document.querySelector(selectorShowMore) !== null) {
    initshowMore();
  }
};

export default {
  init,
};
